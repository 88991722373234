@tailwind base;

@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300..900&display=swap');

@layer base {
	h1 {
		@apply text-2xl font-bold;
	}
	h2 {
		@apply text-xl font-bold;
	}

	.scrollbar-hide {
		-ms-overflow-style: none;
		scrollbar-width: none;  
	 }
  
	 .scrollbar-hide::-webkit-scrollbar {
		display: none;
	 }
}

/* bright component icon fix */
.fill-current {
    fill: currentColor;
}

@tailwind utilities;
@tailwind components;

@layer components {
	.react-tabs__tab--selected {
	   @apply border-b-[3px] border-solid border-primary-700 font-semibold;
	}
	
	.react-tabs__tab--disabled {
	   @apply  cursor-not-allowed text-link-disabled border-link-disabled;
	}
 }
